
















































import { Watch, Component, Vue, Prop } from "vue-property-decorator";
import NoDataHtml from "@/components/common/NoDataHtml.vue";
import { rightRoundedRect } from "@/helper/charts";
import { getGermanNum } from "@/helper/utils";
import { getRouter } from "../../router";
import store from "@/store";
declare const d3: any;

@Component({ components: { NoDataHtml } })
export default class MultiHorizontalBar extends Vue {
  @Prop() id!: string;
  @Prop() dataId!: string;

  chart: any = [];
  noDataFound: Boolean = false;

  get chartData() {
    return {
      data: store.getters.currentCalcs,
      resize: store.getters.resize,
    };
  }

  mounted() {
    this.onDataChange();
  }

  viewBookingRecordByAccount(accountCode: any) {
    localStorage.setItem(
      "buchungssaetze_filter_konto",
      JSON.stringify([accountCode])
    );
    localStorage.setItem("from", this.id);
    getRouter().push("/buchungssaetze");
  }

  @Watch("chartData")
  onDataChange() {
    if (!this.chartData.data) {
      this.noDataFound = true;
      return;
    }
    var d = this.chartData.data[this.dataId];
    var dd = [];
    for (var i in d) {
      dd.push([d[i][0], d[i][2], d[i][1]]);
    }
    this.chartMultiHorizontalBar(dd, this.id);
  }

  chartMultiHorizontalBar(d0: any, id: any) {
    this.noDataFound = false;
    if (!d3.selectAll("#" + id).node()) {
      return;
    }
    var html = "";
    var maxVal = 1;
    this.chart = [];
    var el: any = document.getElementById("initial_width");
    for (var k = 0; k < d0.length; k++) {
      if (d0[k][1] > maxVal) {
        maxVal = d0[k][1];
      }
      var width: number = el.offsetWidth - 10;
      var height: number = 32;
      this.chart.push({
        hor_num: (0 + (k + 1).toString()).slice(-2),
        hor_code: d0[k][0],
        hor_label: d0[k][2],
        svg_wrapper_id: id + k,
        hor_value:
          d0[k][1] / 1000000 >= 1
            ? getGermanNum((d0[k][1] / 1000000).toFixed(1)) + "M"
            : getGermanNum((d0[k][1] / 1000).toFixed(1)) + "K",
        path_d: rightRoundedRect(
          0,
          height / 4,
          (d0[k][1] * width) / maxVal,
          height / 2,
          4
        ),
      });
    }
    return;
  }
}
