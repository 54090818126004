

















import Widget from "@/components/common/Widget.vue";
import StaticTable from "@/components/table/StaticTable.vue";
import VerticalLineAndBarSingleChart from "@/components/charts_js/VerticalLineAndBarSingleChart.vue";
import DashboardItemWrapper from "@/components/DashboardItems/DashboardItemWrapper.vue";
import Vue from "vue";

import Component from "vue-class-component";
import store from "@/store";

@Component({
  components: {
    Widget,
    DashboardItemWrapper,
    VerticalLineAndBarSingleChart,
    StaticTable,
  },
})
export default class KostenUndUmsaetze extends Vue {
  public static id = "KostenUndUmsaetze";
  public static dprops = { width: 4, height: 4, minWidth: 2, minHeight: 2 };
  get _id() {
    return KostenUndUmsaetze.id;
  }
  get _dprops() {
    return KostenUndUmsaetze.dprops;
  }
  get kosten() {
    let kostenHead: any = [];
    store.watch(
      (getter) => {
        if (
          getter.previousCalcs &&
          (getter.previousCalcs as any).KOSTENDETAILS
        ) {
          kostenHead = [
            { title: "Bereich", class: "vertical-align-middle" },
            {
              title: "Werte",
              description:
                store.getters.selectedYear && store.getters.selectedYear[0],
              class: "text-center",
            },
            {
              title: "Vergleich",
              description:
                store.getters.selectedYear && store.getters.selectedYear[0] - 1,
              class: "text-center",
            },
            {
              title: "Abweichung",
              description: "Ist/Referenz",
              class: "text-center",
              colspan: 2,
            },
          ];
        } else {
          kostenHead = [
            { title: "Bereich", class: "vertical-align-middle" },
            {
              title: "Werte",
              description:
                store.getters.selectedYear && store.getters.selectedYear[0],
              class: "text-center",
            },
          ];
        }
      },
      (getters) => {}
    );
    return {
      head: kostenHead,
      body: {
        prev:
          store.getters.previousCalcs &&
          store.getters.previousCalcs.KOSTENDETAILS,
        current:
          store.getters.currentCalcs &&
          store.getters.currentCalcs.KOSTENDETAILS,
      },
    };
  }
}
